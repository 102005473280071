import React from 'react';
import ReactDOM from 'react-dom';
import '@asu/unity-bootstrap-theme/dist/css/unity-bootstrap-theme.bundle.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

function InitFunctions() {
  const [run, setRun] = React.useState(false)
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      // Google Tag Manager
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W3FGR6T');

      initDataLayer();
      initGlobalHeader(); 

      // If you want to start measuring performance in your app, pass a function
      // to log results (for example: reportWebVitals(console.log))
      // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
      reportWebVitals();

      setRun(true)

    }, 1000)

    return () => clearTimeout(timeout)
  }, []);

  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    <InitFunctions />
  </React.StrictMode>,
  document.getElementById('root')
);
